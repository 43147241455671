import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue'
import Register from './views/Register.vue'


const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: "/",
            redirect:"/account",
        },
        {
            path: "/account/login",
            name:"login",
            component: Login,
        },
        {
            path: "/account/register",
            name:"register",
            component: Register,
        }
    ]
});

export default router
