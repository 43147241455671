<template>
    <router-view />
</template>

<script>
export default {
    data: function () {
        return {};
    },
    methods: {},
    mounted: function () {
        this.$router.push({ name: "login" });
    },
};
</script>
